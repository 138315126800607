var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-card',[_c('v-tabs',{attrs:{"centered":"","grow":""},on:{"change":_vm.updateTabNotifications},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},_vm._l((_vm.tabs),function(tabType){return _c('v-tab',{key:tabType},[_c('v-badge',{attrs:{"inline":"","color":_vm.tabColor(tabType),"content":_vm.tabNotificationCount(tabType)}},[_c('v-icon',[_vm._v(_vm._s(_vm.tabIcon(tabType)))]),_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(("$vuetify.notification.type." + tabType)))+" ")],1)],1)}),1),_c('v-data-table',{attrs:{"item-key":"id","headers":_vm.selectedTabHeaders,"items":_vm.tabNotifications,"search":_vm.searchQuery,"custom-filter":_vm.customTableFilter,"items-per-page":_vm.itemsPerPage,"loading":_vm.loading || _vm.deleteInProgress,"height":"calc(100vh - 300px)","fixed-header":"","show-select":"","hide-default-footer":""},on:{"click:row":_vm.notificationSelectHandle},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.notification.search'),"disabled":_vm.loading,"filled":"","clearable":"","hide-details":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})]},proxy:true},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"primary"}},[_c('v-icon',[_vm._v(_vm._s(_vm.iconForType(item.type)))])],1)]}},{key:"item.tags",fn:function(ref){
var item = ref.item;
return [_c('v-chip-group',{attrs:{"column":""}},_vm._l((item.tags),function(tag){return _c('v-chip',{key:tag.name,attrs:{"outlined":"","color":_vm.stringToColor(tag.name)},on:{"click":function($event){$event.stopPropagation();_vm.searchQuery = tag.name}}},[_vm._v(" "+_vm._s(tag.name)+" "),_c('v-avatar',{attrs:{"right":""}},[_vm._v(_vm._s(tag.count))])],1)}),1)]}},{key:"item.releaseDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.releaseDate).toLocaleDateString(_vm.locale, { weekday: "short", day: "numeric", month: "short", year: "numeric" }))+" ")]}},{key:"item.originalBody",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.removeMarkdown(item.originalBody))+" ")]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-snackbar',{attrs:{"color":"error","type":"primary","transition":"scale-transition"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.showError = false}}},[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.close")))])]}}]),model:{value:(_vm.showError),callback:function ($$v) {_vm.showError=$$v},expression:"showError"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]),_c('song-notification-popup',{attrs:{"show-dialog":_vm.clicked != null,"close-dialog":function () { return (_vm.clicked = null); },"notification":_vm.clicked,"delete-notification":_vm.deleteNotifications}})],1),_c('v-btn',{staticClass:"ma-3",attrs:{"width":"80%","disabled":_vm.selected.length === 0 || _vm.loading || _vm.deleteInProgress,"color":"error","type":"primary"},on:{"click":function($event){return _vm.deleteNotifications(_vm.selected)}}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.delete"))+" ")]),_c('v-pagination',{staticClass:"ma-3",attrs:{"value":_vm.page,"length":_vm.numberOfPages(),"total-visible":7,"disabled":_vm.loading},on:{"input":_vm.changePage}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }